// http://localhost:5173/test
import { saveAs } from 'file-saver';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Cookies from 'universal-cookie';
 const cookies = new Cookies(null, { path: '/' });

const baseUrl=process.env.REACT_APP_BASE_URL

const baseQuery = fetchBaseQuery({
  baseUrl: `${baseUrl}/v1/admin`,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    // const token = cookies.get("adtoken");
    const token = localStorage.getItem("adtoken")
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});


const baseQueryWithAuthHandling = async (args, api) => {
  const result = await baseQuery(args, api);

  if (result.error && result.error.status === 401) {
    // cookies.remove("adtoken")
    localStorage.removeItem("adtoken")
    window.location.href = '/admn/login';
  }

  return result;
};


export const AdminApi = createApi({
  reducerPath: 'admin',
  baseQuery: baseQueryWithAuthHandling,
  endpoints: (builder) => ({ 
    adminLogin: builder.mutation({
      query: (payload) => ({
        url: '/login',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['admin'],
    }),
    adminUpdate: builder.mutation({
      query: (payload) => ({
        url: '/update-details',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['admin'],
    }),  
    adminUpdatePassword: builder.mutation({
      query: (payload) => ({
        url: '/change-password',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['admin'],
    }),  
    adminLogout: builder.mutation({
      query: (payload) => ({
        url: '/logout',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['admin'],
    }),  
    getadminDetails: builder.query({
      query: () => `/details`,
      providesTags: ['admin'],
    }),
    getBrands: builder.query({
      query: ({page,rowsPerPage,filter,searchValue}) => {
        return{
          url:`/brand-list/?page=${page}&limit=${rowsPerPage}&searchby=${filter}&keyword=${searchValue}`
        }
      },
      providesTags: ['brand'],
    }),
    getSingleBrand: builder.query({
      query: (id) => `/brand-by-id/${id}`,
      providesTags: ['brand'],
    }),
    addNewBrand: builder.mutation({
      query: (payload) => ({
        url: '/create-brand',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['brand'],
      
    }),
    updateBrand: builder.mutation({
      query: ({formdata,id}) => { // Why is 'end' always undefined???
        return {
          url: `/update-brand/${id}`,
          method:"PUT",
          body: formdata,  
        };
      },
      invalidatesTags: ['brand'],
      
    }),
    deleteBrand:builder.mutation({
      query: ({data}) => { 
        return {
          url: '/delete-brands',
          method:'DELETE',
          body: {data:data},  
        };
      },
        invalidatesTags: ['brand'],
    }),
    getExperience: builder.query({
      query: ({page,rowsPerPage,filter,searchValue}) => {
        return{
          url:`/experience-list/?page=${page}&limit=${rowsPerPage}&searchby=${filter}&keyword=${searchValue}`
        }
      },
      providesTags: ['experience'],
    }),
    getSingleExperience: builder.query({
      query: (id) => `/experience-by-id/${id}`,
      providesTags: ['experience'],
    }),
    addNewExperience: builder.mutation({
      query: (payload) => ({
        url: '/create-experience',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['experience'],
      
    }),
    getTestimonial: builder.query({
      query: ({page,rowsPerPage,filter,searchValue}) => {
        return{
          url:`/testimonial-list/?page=${page}&limit=${rowsPerPage}&searchby=${filter}&keyword=${searchValue}`
        }
      },
      providesTags: ['brand'],
    }),
    getSingleTestimonial: builder.query({
      query: (id) => `/testimonial-by-id/${id}`,
      providesTags: ['brand'],
    }),
    addNewTestimonial: builder.mutation({
      query: (payload) => ({
        url: '/create-testimonial',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['brand'],
      
    }),
    updateTestimonial: builder.mutation({
      query: ({formdata,id}) => { // Why is 'end' always undefined???
        return {
          url: `/update-testimonial/${id}`,
          method:"PUT",
          body: formdata,  
        };
      },
      invalidatesTags: ['brand'],
      
    }),
    deleteTestimonial:builder.mutation({
      query: ({data}) => { 
        return {
          url: '/delete-testimonial',
          method:'DELETE',
          body: {data:data},  
        };
      },
        invalidatesTags: ['brand'],
    }),
    updateExperience: builder.mutation({
      query: ({formdata,id}) => { // Why is 'end' always undefined???
        return {
          url: `/update-experience/${id}`,
          method:"PUT",
          body: formdata,  
        };
      },
      invalidatesTags: ['experience'],
      
    }),
    deleteExperience:builder.mutation({
      query: ({data}) => { 
        return {
          url: '/delete-experiences',
          method:'DELETE',
          body: {data:data},  
        };
      },
        invalidatesTags: ['experience'],
    }),
    getEducation: builder.query({
      query: ({page,rowsPerPage,filter,searchValue}) => {
        return{
          url:`/education-list/?page=${page}&limit=${rowsPerPage}&searchby=${filter}&keyword=${searchValue}`
        }
      },
      providesTags: ['education'],
    }),
    getSingleEducation: builder.query({
      query: (id) => `/education-by-id/${id}`,
      providesTags: ['education'],
    }),
    addNewEducation: builder.mutation({
      query: (payload) => ({
        url: '/create-education',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['education'],
      
    }),
    updateEducation: builder.mutation({
      query: ({formdata,id}) => { // Why is 'end' always undefined???
        return {
          url: `/update-education/${id}`,
          method:"PUT",
          body: formdata,  
        };
      },
      invalidatesTags: ['education'],
      
    }),
    deleteEducation:builder.mutation({
      query: ({data}) => { 
        return {
          url: '/delete-education',
          method:'DELETE',
          body: {data:data},  
        };
      },
        invalidatesTags: ['education'],
    }),
    getLanguage: builder.query({
      query: ({page,rowsPerPage,filter,searchValue}) => {
        return{
          url:`/language-list/?page=${page}&limit=${rowsPerPage}&searchby=${filter}&keyword=${searchValue}`
        }
      },
      providesTags: ['language'],
    }),
    getSingleLanguage: builder.query({
      query: (id) => `/language-by-id/${id}`,
      providesTags: ['language'],
    }),
    addNewLanguage: builder.mutation({
      query: (payload) => ({
        url: '/create-language',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['language'],
      
    }),
    updateLanguage: builder.mutation({
      query: ({formdata,id}) => { // Why is 'end' always undefined???
        return {
          url: `/update-language/${id}`,
          method:"PUT",
          body: formdata,  
        };
      },
      invalidatesTags: ['language'],
      
    }),
    deleteLanguage:builder.mutation({
      query: ({data}) => { 
        return {
          url: '/delete-languages',
          method:'DELETE',
          body: {data:data},  
        };
      },
        invalidatesTags: ['language'],
    }),
    getTag: builder.query({
      query: ({page,rowsPerPage,filter,searchValue}) => {
        return{
          url:`/tag-list/?page=${page}&limit=${rowsPerPage}&searchby=${filter}&keyword=${searchValue}`
        }
      },
      providesTags: ['tags'],
    }),
    getSingleTag: builder.query({
      query: (id) => `/tag-by-id/${id}`,
      providesTags: ['tags'],
    }),
    addNewTag: builder.mutation({
      query: (payload) => ({
        url: '/create-tag',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['tags'],
      
    }),
    updateTag: builder.mutation({
      query: ({formdata,id}) => { // Why is 'end' always undefined???
        return {
          url: `/update-tag/${id}`,
          method:"PUT",
          body: formdata,  
        };
      },
      invalidatesTags: ['tags'],
      
    }),
    deleteTag:builder.mutation({
      query: ({data}) => { 
        return {
          url: '/delete-tags',
          method:'DELETE',
          body: {data:data},  
        };
      },
        invalidatesTags: ['tags'],
    }),
    getCareer: builder.query({
      query: ({page,rowsPerPage,filter,searchValue}) => {
        return{
          url:`/career-list/?page=${page}&limit=${rowsPerPage}&searchby=${filter}&keyword=${searchValue}`
        }
      },
      providesTags: ['career'],
    }),
    getSingleCareer: builder.query({
      query: (id) => `/career-by-id/${id}`,
      providesTags: ['career'],
    }),
    addNewCareer: builder.mutation({
      query: (payload) => ({
        url: '/create-career',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['career'],
      
    }),
    updateCareer: builder.mutation({
      query: ({formdata,id}) => { // Why is 'end' always undefined???
        return {
          url: `/update-career/${id}`,
          method:"PUT",
          body: formdata,  
        };
      },
      invalidatesTags: ['career'],
      
    }),
    deleteCareer:builder.mutation({
      query: ({data}) => { 
        return {
          url: '/delete-careers',
          method:'DELETE',
          body: {data:data},  
        };
      },
        invalidatesTags: ['career'],
    }),
    getBlog: builder.query({
      query: ({page,rowsPerPage,filter,searchValue}) => {
        return{
          url:`/blog-list/?page=${page}&limit=${rowsPerPage}&searchby=${filter}&keyword=${searchValue}`
        }
      },
      providesTags: ['career'],
    }),
    getSingleBlog: builder.query({
      query: (id) => `/blog-by-id/${id}`,
      providesTags: ['career'],
    }),
    addNewBlog: builder.mutation({
      query: (payload) => ({
        url: '/create-blog',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['blog'],
      
    }),
    updateBlog: builder.mutation({
      query: ({formdata,id}) => { 
        return {
          url: `/update-blog/${id}`,
          method:"PUT",
          body: formdata,  
        };
      },
      invalidatesTags: ['blog'],
      
    }),
    deleteBlog:builder.mutation({
      query: ({data}) => { 
        return {
          url: '/delete-blog',
          method:'DELETE',
          body: {data:data},  
        };
      },
        invalidatesTags: ['blog'],
    }),
    getCategories: builder.query({
      query: ({page,rowsPerPage,filter,searchValue}) => {
        return{
          url:`/category-list/?page=${page}&limit=${rowsPerPage}&searchby=${filter}&keyword=${searchValue}`
        }
      },
      providesTags: ['category'],
    }),
    getParentCategories: builder.query({
      query: () => {
        return{
          url:`/parent-category-list`
        }
      },
      providesTags: ['category'],
    }),
    getJobDetails: builder.query({
      query: ({page,rowsPerPage,filter,searchValue}) => {
        return{
          url:`/job-list/?page=${page}&limit=${rowsPerPage}&searchby=${filter}&keyword=${searchValue}`
        }
      },
      providesTags: ['category'],
    }),
    
    getSingleCategory: builder.query({
      query: (id) => `/category-by-id/${id}`,
      providesTags: ['category'],
    }),
    addNewCategory: builder.mutation({
      query: (payload) => ({
        url: '/create-category',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['category'],
      
    }),
    updateCategory: builder.mutation({
      query: ({formdata,id}) => { // Why is 'end' always undefined???
        return {
          url: `/update-category/${id}`,
          method:"PUT",
          body: formdata,  
        };
      },
      invalidatesTags: ['category'],
      
    }),
    deleteCategory:builder.mutation({
        query:({data})=>({
            url:`/delete-category`,
            method:'DELETE',
            body: {data:data},
        }),
        invalidatesTags: ['category'],
    }),
    getcontact: builder.query({
      query: ({page,rowsPerPage,searchValue}) => {
        return{
          url:`/contact-list/?page=${page}&limit=${rowsPerPage}&keyword=${searchValue}`
        }
      },
      providesTags: ['location'],
    }),
    getLocation: builder.query({
      query: ({page,rowsPerPage,filter,searchValue}) => {
        return{
          url:`/location-list/?page=${page}&limit=${rowsPerPage}&searchby=${filter}&keyword=${searchValue}`
        }
      },
      providesTags: ['location'],
    }),
    getSingleLocation: builder.query({
      query: (id) => `/location-by-id/${id}`,
      providesTags: ['location'],
    }),

    getSubscription: builder.query({
      query: ({page,rowsPerPage,filter,searchValue}) => {
        return{
          url:`/subscription-list/?page=${page}&limit=${rowsPerPage}&searchby=${filter}&keyword=${searchValue}`
        }
      },
      providesTags: ['location'],
    }),
    getSingleSubscription: builder.query({
      query: (id) => `/subscription-by-id/${id}`,
      providesTags: ['location'],
    }),

    updateSubscription: builder.mutation({
      query: ({formdata,id}) => { 
        return {
          url: `/update-subscription/${id}`,
          method:"PUT",
          body: formdata,  
        };
      },
      invalidatesTags: ['location'],
      
    }),

    getSubAdmin: builder.query({
      query: () => {
        return{
          url:`/subadmin-list/`
        }
      },
      providesTags: ['location'],
    }),
    getSingleSubAdmin: builder.query({
      query: (id) => `/subadmin-by-id/${id}`,
      providesTags: ['location'],
    }),
    updateSubAdmin: builder.mutation({
      query: ({formdata,id}) => { 
        return {
          url: `/update-subadmin/${id}`,
          method:"PUT",
          body: formdata,  
        };
      },
      invalidatesTags: ['location'],
      
    }),
    addNewLocation: builder.mutation({
      query: (payload) => ({
        url: '/create-location',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['location'],
    }),
    updateLocation: builder.mutation({
      query: ({formdata,id}) => { // Why is 'end' always undefined???
        return {
          url: `/update-location/${id}`,
          method:"PUT",
          body: formdata,  
        };
      },
      invalidatesTags: ['location'],
      
    }),
    deleteLocation:builder.mutation({
      query: ({data}) => { 
        return {
          url: '/delete-locations',
          method:'DELETE',
          body: {data:data},  
        };
      },
        invalidatesTags: ['location'],
    }),
    getJobs: builder.query({
      query: ({page,rowsPerPage,filter,searchValue,recent,featured,recruiter}) => {
        return{
          url:`/job-list/?page=${page}&limit=${rowsPerPage}&searchby=${filter}&keyword=${searchValue}&recent=${recent}&featured=${featured}&recruiter=${recruiter}`
        }
      },
      providesTags: ['category'],
    }),
    getCondidates: builder.query({
      query: ({page,rowsPerPage,filter,searchValue,recent}) => {
        
        return{
          url:`/condidate-list/?page=${page}&limit=${rowsPerPage}&searchby=${filter}&keyword=${searchValue}&recent=${recent}`
        }
      },
      providesTags: ['brand'],
    }),
    getRecruiters: builder.query({
      query: ({page,rowsPerPage,filter,searchValue,recent,billing}) => {
        
        return{
          url:`/recruiter-list/?page=${page}&limit=${rowsPerPage}&searchby=${filter}&keyword=${searchValue}&recent=${recent}&billing=${billing}`
        }
      },
      providesTags: ['brand'],
    }),
    changeStatusRecruiter: builder.mutation({
      query: (payload) => ({
        url: '/status-recruiter',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['location'],
    }),
    changeStatusJob: builder.mutation({
      query: (payload) => ({
        url: '/status-jobs',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['location'],
    }),
    changeFeaturedJob: builder.mutation({
      query: (payload) => ({
        url: '/featured-jobs',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['location'],
    }),
    forgotPassword: builder.mutation({
      query: (payload) => ({
        url: '/forgot-password',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['location'],
    }),
    resetPassword: builder.mutation({
      query: (payload) =>{
        return {
          url: `/password/reset/${payload?.token}`,
        method: 'POST',
        body: payload,
        }
      },
      invalidatesTags: ['location'],
    }),
    changeStatusCondidate: builder.mutation({
      query: (payload) => ({
        url: '/status-condidate',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['location'],
    }),
    getNotification: builder.query({
      query: ({search,page}) => `/notification?search=${search}&page=${page}`,
      providesTags: ['condidate'],
      refetch: (arg, { dispatch, getState }) => ({
        interval: 4*60*1000, // refetch every 1 second
      }),
    }),
    deleteNotification:builder.mutation({
      query: ({data}) => { 
        return {
          url: '/delete-notification',
          method:'DELETE',
          body: {data:data},  
        };
      },
        invalidatesTags: ['recruiter'],
    }),
    deletecontact:builder.mutation({
      query: ({data}) => { 
        return {
          url: '/delete-contact',
          method:'DELETE',
          body: {data:data},  
        };
      },
        invalidatesTags: ['recruiter'],
    }),
    getSingleCandidate: builder.query({
      query: (id) => `/candidate?id=${id}`,
      providesTags: ['recruiter'],
    }),
    deleteCondidate:builder.mutation({
      query: ({data}) => { 
        return {
          url: '/delete-condidate',
          method:'DELETE',
          body: {data:data},  
        };
      },
        invalidatesTags: ['brand'],
    }),
    deleteRecruiter:builder.mutation({
      query: ({data}) => { 
        return {
          url: '/delete-recruiter',
          method:'DELETE',
          body: {data:data},  
        };
      },
        invalidatesTags: ['brand'],
    }),
    deleteJob:builder.mutation({
      query: ({data}) => { 
        return {
          url: '/delete-job',
          method:'DELETE',
          body: {data:data},  
        };
      },
        invalidatesTags: ['brand'],
    }),
    changeFeatured: builder.mutation({
      query: (payload) => ({
        url: '/change-featured',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['location'],
    }),
    getdetails: builder.query({
      query: () => `/details`,
      providesTags: ['recruiter'],
    }),
    dashboard: builder.query({
      query: () => `/dashboard`,
      providesTags: ['recruiter'],
    }),
    excelData: builder.mutation({
      query: (data) => ({
        url: '/billing-excel',
        method: 'POST',
        body: data,
        transformResponse: (response) => {
            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'export.xlsx');
        },
      }),
      responseHandler: async (response) => {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'export-data.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      },
      invalidatesTags: ['location'],
    }),

    addNewPlan: builder.mutation({
      query: (payload) => ({
        url: '/create-subscription',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['tags'],
      
    }),
    getplanByrecruiter: builder.query({
      query: ({id}) => `/get-custom-plan/${id}`,
      providesTags: ['tags'],
    }),
    deletePlan:builder.mutation({
      query: ({data}) => { 
        return {
          url: '/delete-custom-plan',
          method:'DELETE',
          body: {data:data},  
        };
      },
        invalidatesTags: ['tags'],
    }),
    getSeo: builder.query({
      query: ({page,rowsPerPage,searchValue}) => {
        return{
          url:`/seo-list/?page=${page}&limit=${rowsPerPage}&search=${searchValue}`
        }
      },
      providesTags: ['tags'],
    }),
    getSingleSeo: builder.query({
      query: (id) => `/seo-by-id/${id}`,
      providesTags: ['tags'],
    }),
    addNewSeo: builder.mutation({
      query: (payload) => ({
        url: '/create-seo',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['tags'],
      
    }),
    updateSeo: builder.mutation({
      query: ({formdata,id}) => { 
        return {
          url: `/update-seo/${id}`,
          method:"PUT",
          body: formdata,  
        };
      },
      invalidatesTags: ['tags'],
      
    }),
    deleteSeo:builder.mutation({
      query: ({data}) => { 
        return {
          url: '/delete-seo',
          method:'DELETE',
          body: {data:data},  
        };
      },
        invalidatesTags: ['tags'],
    }),
    getBillingReports: builder.query({
      query: ({search,page,recruiter,planType,plan}) => `/billing-reports?search=${search}&page=${page}&recruiter=${recruiter}&planType=${planType}&plan=${plan}`,
      providesTags: ['recruiter'],
    }),
    getmessage: builder.query({
      query: ({selected,search}) => `/messages?selected=${selected}&search=${search}`,
      providesTags: ['front'],
    }),
    removeMessage: builder.mutation({
      query: ({id}) => { 
        return {
          url: `/check-message?id=${id}`,
          method:"POST",  
        };
      },
      invalidatesTags: ['tags'],
      
    }),
    getMessagingBox: builder.query({
      query: ({id}) => {
        console.log(id)
        return `/message?id=${id}`
      },
      providesTags: ['front'],
    }),
    createMessage: builder.mutation({
      query: (formdata) => ({
        url: `/create-message`,
        method: 'POST',
        body:formdata
      }),
      invalidatesTags: ['recruiter'],
    }),
    deletePurchase:builder.mutation({
      query: ({data}) => { 
        return {
          url: '/delete-purchase',
          method:'DELETE',
          body: {data:data},  
        };
      },
        invalidatesTags: ['education'],
    }),
  }),

})


export const { 
    useAdminLoginMutation,
    useAdminLogoutMutation,
    useAdminUpdateMutation,
    useAdminUpdatePasswordMutation,
    useGetadminDetailsQuery,
    useGetBrandsQuery,
    useGetSingleBrandQuery,
    useAddNewBrandMutation,
    useDeleteBrandMutation,
    useUpdateBrandMutation,
    useAddNewExperienceMutation,
    useUpdateExperienceMutation,
    useDeleteExperienceMutation,
    useGetExperienceQuery,
    useGetSingleExperienceQuery,
    useAddNewEducationMutation,
    useUpdateEducationMutation,
    useDeleteEducationMutation,
    useGetSingleEducationQuery,
    useGetEducationQuery,
    useAddNewLanguageMutation,
    useUpdateLanguageMutation,
    useDeleteLanguageMutation,
    useGetLanguageQuery,
    useGetSingleLanguageQuery,
    useAddNewTagMutation,
    useUpdateTagMutation,
    useDeleteTagMutation,
    useGetSingleTagQuery,
    useGetTagQuery,
    useAddNewCareerMutation,
    useUpdateCareerMutation,
    useDeleteCareerMutation,
    useGetCareerQuery,
    useGetSingleCareerQuery,
    useAddNewLocationMutation,
    useUpdateLocationMutation,
    useDeleteLocationMutation,
    useGetSingleLocationQuery,
    useGetLocationQuery,
    useGetJobsQuery,
    useGetCondidatesQuery,
    useGetRecruitersQuery,
    useChangeStatusRecruiterMutation,
    useChangeStatusCondidateMutation,
    useChangeStatusJobMutation,
    useGetCategoriesQuery,
useGetSingleCategoryQuery,
useAddNewCategoryMutation,
useUpdateCategoryMutation,
useGetParentCategoriesQuery,
useDeleteCategoryMutation,
useDeleteNotificationMutation,
useGetNotificationQuery,
useGetSingleSubscriptionQuery,
useGetSubscriptionQuery,
useUpdateSubscriptionMutation,
useGetcontactQuery,
useDeletecontactMutation,
useGetSingleCandidateQuery,
useGetdetailsQuery,
useDeleteCondidateMutation,
useDeleteRecruiterMutation,
useDeleteJobMutation,
useChangeFeaturedJobMutation,
useForgotPasswordMutation,
useResetPasswordMutation,
useChangeFeaturedMutation,
useAddNewBlogMutation,
useGetBlogQuery,
useGetSingleBlogQuery,
useDeleteBlogMutation,
useUpdateBlogMutation,
useGetBillingReportsQuery,
useExcelDataMutation,
useDeletePurchaseMutation,
useAddNewTestimonialMutation,
useDeleteTestimonialMutation,
useUpdateTestimonialMutation,
useGetSingleTestimonialQuery,
useGetTestimonialQuery,
useDashboardQuery,
useGetSeoQuery,
useGetSingleSeoQuery,
useAddNewSeoMutation,
useUpdateSeoMutation,
useDeleteSeoMutation,
useGetmessageQuery,
useGetMessagingBoxQuery,
useCreateMessageMutation,
useRemoveMessageMutation,
useGetSubAdminQuery,
useGetSingleSubAdminQuery,
useUpdateSubAdminMutation,
useAddNewPlanMutation,
useGetplanByrecruiterQuery,
useDeletePlanMutation
} = AdminApi;