// http://localhost:5173/test
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'universal-cookie';
const cookies = new Cookies(null, { path: '/' });

const baseUrl=process.env.REACT_APP_BASE_URL

const baseQuery = fetchBaseQuery({
  baseUrl: `${baseUrl}`,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    // const token = cookies.get("condidate")
    const token = localStorage.getItem("token");
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});


const baseQueryWithAuthHandling = async (args, api) => {
  const result = await baseQuery(args, api);
  if (result.error && result.error.status === 401) {
    localStorage.removeItem("token")
    
  }

  return result;
};


export const CondidateApi = createApi({
  reducerPath: 'condidate',
  baseQuery: baseQueryWithAuthHandling,
  endpoints: (builder) => ({ 
    condidateLogin: builder.mutation({
      query: (payload) => ({
        url: '/condidate/login',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['condidate'],
    }),
    condidateUpdate: builder.mutation({
      query: (payload) => ({
        url: '/condidate/update-details',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['condidate'],
    }),  
    condidateUpdatePassword: builder.mutation({
      query: (payload) => ({
        url: '/condidate/change-password',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['condidate'],
    }),  
    condidateLogout: builder.mutation({
      query: (payload) => ({
        url: '/condidate/logout',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['condidate'],
    }),  
    getDetails: builder.query({
      query: ({page}) => `/condidate/details?page=${page||""}`,
      providesTags: ['condidate'],
    }),
    saveJobs: builder.mutation({
      query: (id) => ({
        url: `/condidate/save-job`,
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['condidate'],
    }),
    saveCompany: builder.mutation({
      query: (id) => ({
        url: `/condidate/save-company`,
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['condidate'],
    }),
    getsaveCompany: builder.query({
      query: (id) => `/condidate/get-save-company`,
      providesTags: ['condidate'],
    }),
    removesaveJobs: builder.mutation({
      query: (id) => ({
        url: `/condidate/remove-save-job`,
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['condidate'],
    }),  
    removesaveCompany: builder.mutation({
      query: (id) => ({
        url: `/condidate/remove-save-company`,
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['condidate'],
    }),  
    applyjob: builder.mutation({
      query: (id) => ({
        url: `/condidate/apply-job`,
        method: 'POST',
        body: id,
      }),
      invalidatesTags: ['condidate'],
    }),  
    getsaveJobs: builder.query({
      query: (id) => `/condidate/get-save-job`,
      providesTags: ['condidate'],
    }),
    getAppliedJobs: builder.query({
      query: (id) => `/condidate/get-applied-job`,
      providesTags: ['condidate'],
    }),
    getNotification: builder.query({
      query: ({search,page}) => `/condidate/notification?search=${search}&page=${page}`,
      providesTags: ['condidate'],
    }), 
    getSingleJobwithCredencial: builder.query({
      query: (slug) => `/job/${slug}`,
      providesTags: ['front'],
    }),
    getMessages: builder.query({
      query: () => `/condidate/messages`
    }),
    createMessage: builder.mutation({
      query: (formdata) => ({
        url: `/condidate/create-message`,
        method: 'POST',
        body:formdata
      }),
      invalidatesTags: ['recruiter'],
    }),
    forgotPassword: builder.mutation({
      query: (payload) => ({
        url: '/condidate/forgot-password',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['location'],
    }),
    resetPassword: builder.mutation({
      query: (payload) =>{
        return {
          url: `/condidate/password/reset/${payload?.token}`,
        method: 'POST',
        body: payload,
        }
      },
      invalidatesTags: ['location'],
    }),
    
    updateAndapplyjob: builder.mutation({
      query: (data) => ({
        url: `/condidate/update-details-apply`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['condidate'],
    }), 
  }),
})


export const { 
    useCondidateLoginMutation,
    useCondidateLogoutMutation,
    useCondidateUpdateMutation,
    useGetDetailsQuery,
    useCondidateUpdatePasswordMutation,
    useSaveJobsMutation,
    useRemovesaveJobsMutation,
    useGetsaveJobsQuery,
    useApplyjobMutation,
    useGetAppliedJobsQuery,
    useGetsaveCompanyQuery,
    useSaveCompanyMutation,
    useRemovesaveCompanyMutation,
    useGetNotificationQuery,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useUpdateAndapplyjobMutation,
    useGetSingleJobwithCredencialQuery,
    useGetMessagesQuery,
    useCreateMessageMutation
} = CondidateApi;