import { useEffect } from 'react';


const IsAdmin = ({ children }) => {

  useEffect(() => {
    const authToken = localStorage.getItem("adtoken");
    if (!authToken) {
      window.location.href = '/admn/login';
    }
  }, []);
  
  return children;
};

export default IsAdmin;