import './App.css';
import {Navigate, useRoutes} from "react-router-dom";
import React, { Suspense, useEffect, useState } from 'react';
import Loading from './components/Loading';
import PagesRoutes from './PagesRoutes';
import 'boxicons/css/boxicons.min.css';
import ScrollToTop from './components/ScrollTop';
import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const withAuth = (WrappedComponent) => {
  return function EnhancedComponent({ ...props }) {
    if (props.error && props.error.status === 401) {
      return <Navigate to="/login" replace />
    }
    return <WrappedComponent {...props} />;
  };
};



function App() {
const visitor = localStorage.getItem("visitorbkj")
  useEffect(() => {
    if(!visitor){
      axios.post(baseURL+'/page-view', { page: window.location.pathname }).then(response => {
        localStorage.setItem('visitorbkj', true);
      });
    }
  }, []);
  
  return (
     <Suspense fallback={<Loading/>}>
      <ScrollToTop/>
     {useRoutes(PagesRoutes)}
     </Suspense>
 
  );
}




export default withAuth(App);
