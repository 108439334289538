import { useEffect } from 'react';

const RedirectCandidate = ({ children }) => {

  useEffect(() => {
    // const authToken = cookies.get('condidate');
    const authToken = localStorage.getItem("token")
    if (authToken) {
      window.location.href = '/candidate/dashboard';
    }
  }, []);

  return children;
};

export default RedirectCandidate;