// http://localhost:5173/test
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl=process.env.REACT_APP_BASE_URL

export const frontendApi = createApi({
  reducerPath: 'Front',
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl,
    tagTypes: ['front'],
    prepareHeaders: (headers) => {
      return headers
      },
      credentials:"include",  
}
  ),
  endpoints: (builder) => ({
    sendMailVerify: builder.mutation({
      query: (payload) => ({
        url: '/recruiter-verify-mail',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['front'],
    }),  
    recruiterRegister: builder.mutation({
      query: (payload) => ({
        url: '/recruiter-register',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['front'],
    }),   
    sendMailVerifyCondidate: builder.mutation({
      query: (payload) => ({
        url: '/condidate-verify-mail',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['front'],
    }),  
    condidateRegister: builder.mutation({
      query: (payload) => ({
        url: '/register',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['front'],
    }),   
    getLocation: builder.query({
      query: () => `/locations`,
      providesTags: ['front'],
    }),
    getcaptchaSvg: builder.query({
      query: () => `/captcha`,
      providesTags: ['front'],
    }),
    getSkills: builder.query({
      query: () => `/skills`,
      providesTags: ['front'],
    }),
    getIndustry: builder.query({
      query: () => `/industry`,
      providesTags: ['front'],
    }),
    getHomeIndustry: builder.query({
      query: () => `/home-industry`,
      providesTags: ['front'],
    }),
    getHomeCategory: builder.query({
      query: () => `/home-category`,
      providesTags: ['front'],
    }),
    getHomeCompanies: builder.query({
      query: () => `/home-companies`,
      providesTags: ['front'],
    }),
    getCategory: builder.query({
      query: () => `/category`,
      providesTags: ['front'],
    }),
    getHomeLocation: builder.query({
      query: () => `/home-location`,
      providesTags: ['front'],
    }),
    getSubCategory: builder.query({
      query: (id) => `/sub-category?category=${id}`,
      providesTags: ['front'],
    }),
    getTotalSubCategory: builder.query({
      query: ({search}) => `/only-sub-category?search=${search}`,
      providesTags: ['front'],
    }),
    getSubcripiton: builder.query({
      query: () => `/subscription`,
      providesTags: ['front'],
    }),
    getRecruiterDetails: builder.query({
      query: (id) => `/recruiter-details/${id}`,
      providesTags: ['front'],
    }),
    getResumePlan: builder.query({
      query: () => `/resume-plan`,
      providesTags: ['front'],
    }),
    getJobdetails: builder.query({
      query: (id) => `/job-details/${id}`,
      providesTags: ['front'],
    }),
    getEducationLevel: builder.query({
      query: () => `/education-level`,
      providesTags: ['front'],
    }),
    getExperienceLevel: builder.query({
      query: () => `/experience-level`,
      providesTags: ['front'],
    }),
    getAllLanguage: builder.query({
      query: () => `/languages`,
      providesTags: ['front'],
    }),
    getlatestJobs: builder.query({
      query: () => `/latest-jobs`,
      providesTags: ['front'],
    }),
    getSingleJob: builder.query({
      query: (slug) => `/job/${slug}`,
      providesTags: ['front'],
    }),
    viewCount: builder.query({
      query: (slug) => `/job-view-count?slug=${slug}`,
      providesTags: ['front'],
    }),
    blogs: builder.query({
      query: ({page,hashtag}) => `/blogs?page=${page}&hashtag=${hashtag}`,
      providesTags: ['front'],
    }),
    singleBlog: builder.query({
      query: (slug) => `/blog/${slug}`,
      providesTags: ['front'],
    }),
    setting: builder.query({
      query: () => `/settings`,
      providesTags: ['front'],
    }),
    brands: builder.query({
      query: () => `/brands`,
      providesTags: ['front'],
    }),
    Homebrands: builder.query({
      query: () => `/home-brands`,
      providesTags: ['front'],
    }),
    getCompanies: builder.query({
      query: ({filter,page}) => {
        console.log(filter)
        return `/companies?companyType=${filter}&page=${page}`
      },
      providesTags: ['front'],
    }),
    getAllLocations: builder.query({
      query: (page) => `/all-locations?page=${page}`,
      providesTags: ['front'],
    }),
    getAllDepartments: builder.query({
      query: (page) => `/all-departments?page=${page}`,
      providesTags: ['front'],
    }),
    getTestimonial: builder.query({
      query: () => `/testimonial`,
      providesTags: ['front'],
    }),
    getMeta: builder.query({
      query: (page) => `/meta/${page}`,
      providesTags: ['front'],
    }),
    CreateContact: builder.mutation({
      query: (payload) => ({
        url: '/contact',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['condidate'],
    }),
    getAllJobs: builder.query({
      query: ({selectedInd,
        selectedJobd,
        selectedlction,
        selectedSkill,
        short,
        selectedExperience,
        jobType,qualification,
        company,
      salary,page,selectedBrand}) => `/listing-jobs?location=${selectedlction.join(",")}&experience=${selectedExperience.join(",")}&industry=${selectedInd}${selectedJobd.length !==0?`&department=${selectedJobd.join(",")}`:""}&skill=${selectedSkill.join(",")}&short=${short}&jobtype=${jobType.join(",")}&education=${qualification.join(",")}&company=${company.join(",")}&salary=${salary.join(",")}&page=${page}&brand=${selectedBrand.join(",")}`,
      providesTags: ['front'],
    }),
  }),
})


export const { useRecruiterRegisterMutation,
useGetResumePlanQuery,
useGetSubcripitonQuery ,
useGetLocationQuery,
useSendMailVerifyMutation,
useGetSkillsQuery,
useGetIndustryQuery,
useGetCategoryQuery,
useGetSubCategoryQuery,
useGetJobdetailsQuery,
useGetEducationLevelQuery,
useGetExperienceLevelQuery,
useCondidateRegisterMutation,
useSendMailVerifyCondidateMutation,
useGetTotalSubCategoryQuery,
useGetAllLanguageQuery,
useGetlatestJobsQuery,
useGetSingleJobQuery,
useViewCountQuery,
useGetRecruiterDetailsQuery,
useGetCompaniesQuery,
useGetHomeCategoryQuery,
useGetHomeIndustryQuery,
useGetHomeCompaniesQuery,
useGetHomeLocationQuery,
useGetAllJobsQuery,
useCreateContactMutation,
useSettingQuery,
useGetAllDepartmentsQuery,
useGetAllLocationsQuery,
useBlogsQuery,
useBrandsQuery,
useSingleBlogQuery,
useGetTestimonialQuery,
useGetMetaQuery,
useGetcaptchaSvgQuery,
useHomebrandsQuery
} = frontendApi;