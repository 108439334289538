import { useEffect } from "react";

const RedirectRecruiter = ({children }) => {
  useEffect(() => {
    const authToken = localStorage.getItem("recruiter");
    if (authToken) {
      window.location.href = '/recruiter/dashboard';
    }
  }, []);
 
  return children;
  };

  export default RedirectRecruiter