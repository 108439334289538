import React from 'react';

const Loading  = ()=>{
     return(
		<div id="preloader">
		<div id="ctn-preloader" className="ctn-preloader">
		  <div className="icon">
			<img
			  src="/assets/images/favicon.png"
			  alt=""
			  className="m-auto d-block"
			  width={40}
			/>
		  </div>
		  <div className="txt-loading">
			<span data-text-preloader="bank" className="letters-loading">
			  bank
			</span>
			<span
			  style={{ color: "#ff0000" }}
			  data-text-preloader="A"
			  className="letters-loading"
			>
			  A
			</span>
			<span data-text-preloader="job" className="letters-loading">
			  job
			</span>
		  </div>
		</div>
	  </div>
     )
}


export default Loading