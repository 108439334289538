import { configureStore } from '@reduxjs/toolkit'
import { frontendApi } from './frontend/frontend';
import { RecruiterApi } from './frontend/recruiter';
import { CondidateApi } from './frontend/condidate';
import { AdminApi } from './frontend/admin';

export const store = configureStore({
  reducer: {
    [frontendApi.reducerPath]:frontendApi.reducer,
    [RecruiterApi.reducerPath]:RecruiterApi.reducer,
    [CondidateApi.reducerPath]:CondidateApi.reducer,
    [AdminApi.reducerPath]:AdminApi.reducer,
  },
  middleware:(getDefaultMiddleware)=>getDefaultMiddleware().concat(frontendApi.middleware,
    RecruiterApi.middleware,
    CondidateApi.middleware,
    AdminApi.middleware,
    )
})