import { useEffect } from "react";

const IsRecruiter = ({children }) => {

  useEffect(() => {
    // const authToken = cookies.get('recruiter');
    const authToken = localStorage.getItem("recruiter");
    if (!authToken) {
      window.location.href = '/recruiter/login';
    }
  }, []);
 
  return children;
  };

  export default IsRecruiter